import styled from 'styled-components/macro'

import { themeColor } from '../../theme'

// TODO: call this Link once the legacy Link component is removed
export const TextLink = styled.a`
  color: ${themeColor('primary')};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`
