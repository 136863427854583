import { useRef, useState } from 'react'
import styled from 'styled-components'
import { Controller, useForm } from 'react-hook-form'
import { isEmpty } from 'lodash'

import { Box, Icon, IconButton, SettableFieldType, Text, TextEditor } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useRunbookCommentCreate } from 'main/services/queries/use-runbook-comments'

export type ActivityFeedInputProps = {
  runbookId: number
  allowAttachments?: boolean
}

const ActivityFeedInputWrapper = styled.form.attrs(() => ({
  noValidate: true,
  role: 'form'
}))`
  display: flex;
  flex-direction: row;
  gap: 8px;
  min-width: 0;
  min-height: 0;
  padding: 8px 16px 8px 0;
  align-items: flex-end;
  width: 100%;
  max-height: 60%;
  overflow-y: auto;
`

type NewActivityMessageType = { comment: string }

export const ActivityFeedInput = ({ runbookId, allowAttachments = false }: ActivityFeedInputProps) => {
  const { t } = useLanguage('activities')
  const textEditorRef = useRef<SettableFieldType>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [file, setFile] = useState<File | undefined>()
  const [forceReRenderKey, setForceReRenderKey] = useState<number>(0)

  const { mutateAsync, isLoading } = useRunbookCommentCreate(runbookId)
  const fileType = file?.type.startsWith('image/') ? 'image' : null
  const { handleSubmit, control, watch, reset } = useForm<NewActivityMessageType>()
  const commentValue = watch('comment')

  const canSubmit = (!isEmpty(commentValue?.trim()) || file) && !isLoading

  const handleClickUpload = () => {
    fileInputRef.current?.click()
  }

  const sendComment = async (data: NewActivityMessageType) => {
    await mutateAsync(
      { comment: data.comment?.trim(), file },
      {
        onSuccess: () => {
          textEditorRef.current?.clear()
          setFile(undefined)
          reset()
          setForceReRenderKey(i => i + 1)
          setTimeout(() => {
            textEditorRef.current?.focus()
          })
        }
      }
    )
  }

  const handlePasteFile = (data: DataTransfer) => {
    if (!allowAttachments) return
    const fileList = data.files
    if (fileList && fileList.length > 0) {
      setFile(fileList[0])
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e?.target.files
    if (fileList && !!fileList.length) {
      setFile(fileList[0])

      e.target.value = ''
    }
  }

  return (
    <ActivityFeedInputWrapper>
      <Box
        direction="column"
        css={`
          flex: 1 1 auto;
          padding-bottom: ${file ? '32px' : undefined};
          max-height: 100%;
          min-width: 0;
          position: relative;
        `}
      >
        <Box
          css={`
            height: 100%;
            overflow-y: scroll;
            border-radius: ${!file ? '0 0 8px 8px' : undefined};
          `}
        >
          <Controller
            name="comment"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextEditor
                name="comment"
                value={value}
                onChange={onChange}
                onPasteFile={handlePasteFile}
                key={forceReRenderKey}
                ref={textEditorRef}
                disabled={isLoading}
                placeholder={t('activities:input:comment:placeholder')}
                aria-label={t('activities:input:comment:placeholder')}
                aria-describedby="instructions"
                plain
                restrictedMode
                hideToolBar
                invertColor
              />
            )}
          />
        </Box>
        {allowAttachments && file && (
          <Box
            background="bg-1"
            direction="row"
            align="center"
            justify-content="flex-start"
            gap="xsmall"
            pad={{ right: '12px', left: '12px' }}
            css={`
              flex-shrink: 0;
              border-radius: 0 0 8px 8px;
              width: 100%;
              position: absolute;
              bottom: 0;
              z-index: 1;
            `}
          >
            <Icon size="small" icon={fileType === 'image' ? 'image' : 'page-text'} color="text-light" />
            <Text color="primary" size="13px" css="line-height: 32px;">
              {file.name}
            </Text>
            <IconButton
              data-testid="removeFileSelection"
              size="small"
              icon="close"
              label={t('activities:feed:removeSelection')}
              onClick={() => setFile(undefined)}
            />
          </Box>
        )}
      </Box>
      {allowAttachments && (
        <>
          {/* TODO: WIP feature, using plain input here as a temporary solution. FileInputField within the Modal will be used instead */}
          <input
            data-testid="fileInput"
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <IconButton
            data-testid="uploadFileButton"
            size="medium"
            icon="attachment"
            disabled={isLoading}
            label={t('activities:feed:uploadFile')}
            onClick={handleClickUpload}
          />
        </>
      )}
      <IconButton
        primary
        disabled={!canSubmit}
        data-testid="sendCommentButton"
        size="medium"
        icon={isLoading ? 'spinner' : 'send'}
        label={t('activities:feed:send')}
        onClick={handleSubmit(sendComment)}
      />
    </ActivityFeedInputWrapper>
  )
}
