import { Dispatch, SetStateAction } from 'react'

import { useNotify } from '@cutover/react-ui'
import { queryClient } from 'main/query-client'
import { QueryKeys } from 'main/services/queries/query-keys'
import { FormModal } from 'main/components/shared/form'
import { useRunbookTypeCreateMutation, useRunbookTypesQuery } from '../use-runbook-type'
import { ApprovalFlowType } from 'main/services/queries/types'
import { ConfigModel } from 'main/data-access'
import { RunbookTypeFormDetails } from '../runbook-type-form-details'
import { buildRunbookTypeSchema, RunbookTypesSchema } from '../schemas/runbook-types-schema'
import { useLanguage } from 'main/services/hooks'

type CreaterRunbookTypeModalType = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const randomElement = <T,>(array: T[]): T => array[Math.floor(Math.random() * array.length)]

export const CreateRunbookTypeModal = ({ open, setOpen }: CreaterRunbookTypeModalType) => {
  const { t } = useLanguage('runbookTypes')
  const notify = useNotify()
  const createRunbookType = useRunbookTypeCreateMutation().mutateAsync
  const { data } = useRunbookTypesQuery()
  const approvalFlows = data?.meta?.available_approval_flows as ApprovalFlowType[]
  const { runbookTypeIcons, runbookTypeColors } = ConfigModel.useGet()

  const defaultValues = {
    global: 'true',
    icon_name: randomElement(runbookTypeIcons),
    icon_color: randomElement(runbookTypeColors)
  }

  const handleSubmit = async (payload: RunbookTypesSchema) => {
    createRunbookType(payload, {
      onSuccess: () => {
        notify.success(t('toasters.add.notification.message'))
        setOpen(false)

        queryClient.invalidateQueries([QueryKeys.RunbookTypes])
      },
      onError: error => {
        notify.error(error?.errors?.[0] || t('toasters.add.notification.failed'))
        setOpen(false)
      }
    })
  }

  return (
    <FormModal<RunbookTypesSchema>
      title={t('addSection.title')}
      confirmText={t('addSection.createButtonText')}
      confirmIcon={'add'}
      open={open}
      onClose={() => setOpen(false)}
      onSubmit={handleSubmit}
      schema={buildRunbookTypeSchema()}
      defaultValues={defaultValues}
    >
      <RunbookTypeFormDetails mode="create" approvalFlows={approvalFlows} />
    </FormModal>
  )
}
