import { useMutation } from 'react-query'

import { apiClient_UNSTABLE } from '../api/api-client-unstable'
import { RunbookEditResponse, RunbookGetResponse, RunbookUpdateResponse } from '../api/data-providers/runbook-types'
import { RunbookCreateRunbook, RunbookUpdateRunbook } from 'main/services/queries/types'
import { apiClient } from '../api'
import { QueryKeys } from './query-keys'

let controller: AbortController | undefined

export const getRunbook = async (runbookId?: number | string) => {
  if (controller) controller.abort()
  controller = new AbortController()
  const signal = controller.signal

  try {
    const { data } = await apiClient_UNSTABLE.get<RunbookGetResponse>(`runbooks/${runbookId}`, { signal })
    return data
  } catch (error: any) {
    // TODO: handle any errors
    throw error
  }
}

export type RunbookUpdatePayload = {
  runbook: RunbookUpdateRunbook
  timezone: string | null
  shift_time?: boolean
}

export type RunbookCreatePayload = {
  runbook: RunbookCreateRunbook
  base_runbook_id?: number | null
  shift_time?: boolean
}

export const getRunbookEdit = async (runbookId: number | string) => {
  const { data } = await apiClient.get<RunbookEditResponse>({
    url: `runbooks/${runbookId}/edit`
  })

  return data
}

export const updateRunbook = async (runbookId: number | string, payload: RunbookUpdatePayload) => {
  const { data } = await apiClient.put<RunbookUpdatePayload, RunbookUpdateResponse>({
    url: `runbooks/${runbookId}`,
    data: payload
  })

  return data
}

export function useRunbooksCreate() {
  return useMutation<any, Error, RunbookCreatePayload>([QueryKeys.Runbooks], async (payload: RunbookCreatePayload) => {
    const response = await apiClient.post<RunbookCreatePayload, any>({
      url: 'runbooks',
      data: payload
    })

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return response.data!
  })
}

export const downloadAuditLogCsv = async (runbookId: number | string) => {
  const { data } = await apiClient_UNSTABLE.post<ArrayBuffer>(`runbooks/${runbookId}/audit_csv`, undefined, {
    responseType: 'arraybuffer'
  })

  return data
}
