import { LoadingPanel, useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { queryClient } from 'main/query-client'
import { QueryKeys } from 'main/services/queries/query-keys'
import { FormEditPanel } from 'main/components/shared/form'
import { ApprovalFlowType } from 'main/services/queries/types'
import { useRunbookTypeQuery, useUpdateRunbookTypeMutation } from '../use-runbook-type'
import { buildRunbookTypeSchema, RunbookTypesSchema } from '../schemas/runbook-types-schema'
import { RunbookTypeFormDetails } from '../runbook-type-form-details'
import { usePermissions } from 'main/services/queries/use-permissions'

export const RunbookTypesEditPanel = () => {
  const [{ runbookTypeId }, { closeRightPanel }] = useRightPanelTypeState('runbook-type-edit-panel')
  return (
    <>
      {runbookTypeId ? (
        <RunbookTypesEdit runbookTypeId={runbookTypeId} onClose={closeRightPanel} key={runbookTypeId} />
      ) : null}
    </>
  )
}

type RunbookTypeEditType = {
  runbookTypeId: number
  onClose: () => void
}

const RunbookTypesEdit = ({ runbookTypeId, onClose }: RunbookTypeEditType) => {
  const { t } = useLanguage('runbookTypes')
  const { data } = useRunbookTypeQuery(runbookTypeId)
  const runbookType = data?.runbook_type
  const updateRunbookType = useUpdateRunbookTypeMutation().mutateAsync
  const notify = useNotify()

  const permissions = usePermissions('runbook-type')
  const canUpdateRunbookType = permissions('update')

  const approvalFlows = data?.meta?.available_approval_flows as ApprovalFlowType[]

  const defaultValues = {
    name: runbookType?.name,
    description: runbookType?.description,
    global: runbookType?.global?.toString(),
    account_id: runbookType?.account_id?.toString() || null,
    approval_flow_id: runbookType?.approval_flow_id,
    incident: runbookType?.incident,
    dynamic: runbookType?.dynamic,
    restrict_create_to_templates: runbookType?.restrict_create_to_templates,
    disabled: runbookType?.disabled,
    enable_rto: runbookType?.enable_rto,
    default: runbookType?.default
  }

  const handleSubmit = async (formData: RunbookTypesSchema) => {
    updateRunbookType(
      { id: runbookTypeId, ...formData },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QueryKeys.RunbookTypes])
          notify.success(t('editSection.notification.successMessage'))
          onClose()
        },
        onError: error => {
          notify.error(error?.errors?.[0] || t('editSection.notification.failedMessage'))
        }
      }
    )
  }

  return runbookType ? (
    <FormEditPanel<RunbookTypesSchema>
      defaultValues={defaultValues}
      onClose={onClose}
      title={t('editSection.title')}
      onSubmit={handleSubmit}
      schema={buildRunbookTypeSchema()}
      disabled={!canUpdateRunbookType} // have to use this or RadioBoxGroupField will not be readOnly
      readOnly={!canUpdateRunbookType}
    >
      <RunbookTypeFormDetails mode="edit" approvalFlows={approvalFlows} />
    </FormEditPanel>
  ) : (
    <LoadingPanel />
  )
}
