import { Ref } from 'react'
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil'
import { produce } from 'immer'
import { extend, isEmpty, pullAllWith } from 'lodash'

import { DependencyType, MenuListItemProps, TaskItemIcon, useNotify } from '@cutover/react-ui'
import {
  accountTaskTypeLookup,
  filteredTaskListIdsState,
  IntegrationRequest,
  isVersionEditable,
  ModalActiveType,
  newTaskStreamState,
  runbookPermission,
  runbookState,
  runbookVersionStageState,
  runbookVersionState,
  runbookViewState_INTERNAL,
  streamsLookupState,
  streamsPermittedState,
  taskListLookupState,
  TaskListMenu
} from 'main/recoil/runbook'
import { useLanguage } from 'main/services/hooks'
import {
  INTEGRATION_FINISHED_STATUSES,
  INTEGRATION_RUNNING_STATUSES,
  IntegrationActionItem,
  IntegrationFinishedStatus,
  IntegrationRunningStatus,
  IntegrationStatus,
  TaskListTask
} from 'main/services/queries/types'
import { fireIntegration } from 'main/services/queries/use-task'
import { useToggleRightPanel } from 'main/components/layout/right-panel'
import { stageIconName, taskTypeIcon } from 'main/components/runbook/pages/task-list/task-item/task-list-item-props'
import { filterSelector } from 'main/recoil/shared/filters'
import { useActiveRunbookCan } from './active-runbook'
import { useActionTaskPermission, useCanTask, useCreateTaskPermission, useDeleteTaskPermission } from './task'
import { RunbookViewModel } from 'main/data-access/view-models'
import { useGetRunbookVersion, useGetRunbookVersionCallback } from './active-runbook-version'
import { useGetDashboardBy, useGetDashboardByCallback } from './dashboard'
import { useGetRun, useGetRunCallback } from './active-run'
import { useGetSavedPirFilter, useGetSavedPirFilterCallback } from './saved-filter'

/* -------------------------------------------------------------------------- */
/*                                 Loading Ids                                */
/* -------------------------------------------------------------------------- */
export const useLoadingIdsValue = () => useRecoilValue(runbookViewState_INTERNAL).loadingIds
export const useLoadingIdsValueCallback = () =>
  useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).loadingIds
  })

export const useLoadingIdAdd = () =>
  useRecoilCallback(
    ({ set }) =>
      (id: number) =>
        set(runbookViewState_INTERNAL, previousState =>
          produce(previousState, draft => {
            draft.loadingIds[id] = true
          })
        )
  )
export const useLoadingIdRemove = () =>
  useRecoilCallback(
    ({ set }) =>
      (id: number) =>
        set(runbookViewState_INTERNAL, previousState =>
          produce(previousState, draft => {
            delete draft.loadingIds[id]
          })
        )
  )
export const useLoadingIdAddBulk = () =>
  useRecoilCallback(
    ({ set }) =>
      (ids: number[]) =>
        set(runbookViewState_INTERNAL, previousState =>
          produce(previousState, draft => {
            ids.forEach(id => (draft.loadingIds[id] = true))
          })
        )
  )
export const useLoadingIdRemoveBulk = () =>
  useRecoilCallback(
    ({ set }) =>
      (ids: number[]) =>
        set(runbookViewState_INTERNAL, previousState =>
          produce(previousState, draft => {
            ids.forEach(id => delete draft.loadingIds[id])
          })
        )
  )

/* -------------------------------------------------------------------------- */
/*                                    Menu                                    */
/* -------------------------------------------------------------------------- */
export const useMenuValue = () => {
  const { menu } = useRecoilValue(runbookViewState_INTERNAL)
  return menu
}
export const useMenuValueCallback = () =>
  useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).menu
  })

export const useMenuToggleTaskAction = () => {
  const buildMenuItems = useBuildTaskActionMenuItems()
  const openMenu = useMenuOpen()
  const closeMenu = useMenuClose()

  return useRecoilCallback(
    ({ snapshot }) =>
      async ({
        task,
        integrationActionItem,
        integrationOptions,
        triggerRef
      }: {
        task: TaskListTask
        integrationActionItem?: IntegrationActionItem
        integrationOptions?: Record<string, object | undefined>
        triggerRef: Ref<HTMLElement>
      }) => {
        const currentMenu = (await snapshot.getPromise(runbookViewState_INTERNAL)).menu

        if (currentMenu.taskId === task.id && currentMenu.type === 'options') {
          return closeMenu()
        }

        const menuItems = await buildMenuItems({ task, integrationActionItem, integrationOptions })
        return openMenu({
          taskId: task.id,
          triggerRef,
          type: 'options',
          keyPrefix: 'task-opts-menu',
          items: menuItems
        })
      }
  )
}

const MIN_DEPENDENCY_MENU_WIDTH = 200
const MAX_DEPENDENCY_MENU_WIDTH = 320
const MAX_DEPENDENCY_MENU_HEIGHT = 280
export const useMenuToggleTaskDependencies = () => {
  const buildMenuItems = useBuildTaskDependencyMenuItems()
  const openMenu = useMenuOpen()
  const closeMenu = useMenuClose()

  return useRecoilCallback(
    ({ snapshot }) =>
      async ({
        task,
        triggerRef,
        dependencyType
      }: {
        task: TaskListTask
        triggerRef: Ref<HTMLElement>
        dependencyType: DependencyType
      }) => {
        const currentMenu = (await snapshot.getPromise(runbookViewState_INTERNAL)).menu

        if (currentMenu.taskId === task.id && currentMenu.type === dependencyType) {
          return closeMenu()
        }

        const items = await buildMenuItems({ task, type: dependencyType })
        return openMenu({
          taskId: task.id,
          triggerRef,
          type: dependencyType,
          items,
          minWidth: MIN_DEPENDENCY_MENU_WIDTH,
          maxHeight: MAX_DEPENDENCY_MENU_HEIGHT,
          maxWidth: MAX_DEPENDENCY_MENU_WIDTH
        })
      },
    []
  )
}

export const useMenuClear = () =>
  useRecoilCallback(({ set }) => () => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.menu.isOpen = false
        draft.menu.taskId = undefined
        draft.menu.type = undefined
        draft.menu.triggerRef = undefined
        draft.menu.keyPrefix = undefined
        draft.menu.items = []
        draft.menu.minWidth = undefined
        draft.menu.maxWidth = undefined
        draft.menu.maxHeight = undefined
      })
    )
  })
export const useMenuOpen = () =>
  useRecoilCallback(({ set }) => (menu: Omit<TaskListMenu, 'open'>) => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        extend(draft.menu, { ...menu, open: true })
      })
    )
  })

export const useMenuClose = () =>
  useRecoilCallback(({ set }) => () => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.menu.open = false
      })
    )
  })

/* ---------------------------- Menu helpers --------------------------- */

const useBuildTaskActionMenuItems = () => {
  const notify = useNotify()
  const { t } = useLanguage('runbook', { keyPrefix: 'taskListItem' })
  const openModal = RunbookViewModel.useAction('modal:open')
  const integrationRequest = useIntegrationRequestValue()
  const setIntegrationRequest = useIntegrationRequestAdd()

  const getCanCreateTask = useCreateTaskPermission()
  const getCanDeleteTask = useDeleteTaskPermission()
  const getCanActionTask = useActionTaskPermission()
  const getCanEditRunbook = useEditRunbookPermissionsCallback()
  const canAddSnippet = useCanTask('add_snippet')

  const copyIdsAdd = useCopyIdsAdd()

  const openTaskCreateForm = useTaskCreateOpenForm()

  return useRecoilCallback(
    ({ snapshot, set }) =>
      async ({
        task,
        integrationActionItem,
        integrationOptions
      }: {
        task: TaskListTask
        integrationActionItem?: IntegrationActionItem
        integrationOptions?: { [x: string]: {} | undefined }
      }) => {
        const { id, name, internal_id: internalId, predecessor_ids: predecessorIds } = task
        const { linked_runbook_details, template_type, ...runbook } = await snapshot.getPromise(runbookState)
        const runbookVersion = await snapshot.getPromise(runbookVersionState)
        const { copyIds } = await snapshot.getPromise(runbookViewState_INTERNAL)
        const { can: canCopy } = await getCanEditRunbook()
        const canDeleteTask = getCanDeleteTask(id)?.can
        const canMultiplyTask = canDeleteTask // multiply same as update same as delete
        const lastIntegrationEvent =
          task.integration_events.length > 0 && task.integration_events[task.integration_events.length - 1]
        const eventStatus: IntegrationStatus = lastIntegrationEvent.status?.toLowerCase()
        const canRefireIntegration =
          INTEGRATION_FINISHED_STATUSES.includes(eventStatus as IntegrationFinishedStatus) &&
          !integrationRequest.hasOwnProperty(task.id)
        const canAbortIntegration =
          INTEGRATION_RUNNING_STATUSES.includes(eventStatus as IntegrationRunningStatus) &&
          integrationOptions?.cancellable &&
          !integrationRequest.hasOwnProperty(task.id)

        const { can: canCreateTaskFromPredecessor } = getCanCreateTask(task.id)
        const canCreateLinkedTask =
          canCreateTaskFromPredecessor &&
          (!linked_runbook_details || isEmpty(linked_runbook_details)) &&
          template_type !== 'snippet'
        const canCreateSnippetTask = canCreateTaskFromPredecessor && template_type !== 'snippet' && canAddSnippet

        const { can: canActionTask } = getCanActionTask(id)

        const menuItems = [
          canMultiplyTask &&
            ({
              label: t('actions.duplicate'),
              a11yTitle: t('actions.duplicate'),
              icon: 'duplicate',
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                openModal({
                  taskId: id,
                  taskName: name,
                  type: 'task-duplicate'
                })
              }
            } as MenuListItemProps),
          canCopy &&
            ({
              label: t('actions.copy'),
              a11yTitle: t('actions.copy'),
              icon: 'copy',
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                copyIdsAdd([id])
                notify.success(t('actions.pasteClipboard'), {
                  title: t('actions.pasteClipboardTitle')
                })
              }
            } as MenuListItemProps),
          canCopy &&
            ({
              label: t('actions.paste'),
              a11yTitle: t('actions.paste'),
              icon: 'paste',
              appendDivider: true,
              disabled: !copyIds.length,
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                openModal({ type: 'tasks-paste', taskId: id, taskName: name })
              }
            } as MenuListItemProps),
          canActionTask &&
            canAbortIntegration &&
            ({
              label: t('actions.abortIntegration'),
              a11yTitle: t('actions.abortIntegration'),
              icon: 'cancel',
              appendDivider: true,
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()

                openModal({
                  taskId: task.id,
                  name: integrationActionItem?.name ?? '',
                  type: 'integration-abort'
                })
              }
            } as MenuListItemProps),
          canActionTask &&
            canRefireIntegration &&
            ({
              label: t('actions.refireIntegration'),
              a11yTitle: t('actions.refireIntegration'),
              icon: 'refresh',
              appendDivider: true,
              onClick: async e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                setIntegrationRequest({ taskId: id, type: 'refire' })
                await fireIntegration({ runbookId: runbook.id, runbookVersionId: runbookVersion.id, taskId: id })
              }
            } as MenuListItemProps),
          canCreateTaskFromPredecessor &&
            ({
              label: t('actions.addTaskAfter'),
              a11yTitle: t('actions.addTaskAfter'),
              icon: 'add',
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                openTaskCreateForm({ predecessor: id })
              }
            } as MenuListItemProps),
          canCreateLinkedTask &&
            ({
              label: t('actions.addLinkedTask'),
              a11yTitle: t('actions.addLinkedTask'),
              icon: 'runbook',
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                openModal({ id, type: 'linked-runbook-add' })
              }
            } as MenuListItemProps),
          canCreateSnippetTask &&
            ({
              label: t('actions.addSnippet'),
              a11yTitle: t('actions.addSnippet'),
              icon: 'snippet',
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                openModal({ id, type: 'snippet-add' })
              }
            } as MenuListItemProps),
          {
            label: t('actions.showCriticalPath'),
            a11yTitle: t('actions.showCriticalPath'),
            icon: 'critical-path',
            disabled: !predecessorIds.length, // Note: disabling instead of omitting so no chance of completely empty menu
            onClick: e => {
              e.syntheticEvent.stopPropagation()
              e.syntheticEvent.preventDefault()
              set(filterSelector({ attribute: 'critical_to_here' }), internalId)
            }
          } as MenuListItemProps,
          {
            label: t('actions.showAncestors'),
            a11yTitle: t('actions.showAncestors'),
            icon: 'predecessors',
            disabled: !predecessorIds.length,
            onClick: e => {
              e.syntheticEvent.stopPropagation()
              e.syntheticEvent.preventDefault()
              set(filterSelector({ attribute: 'predecessors_to_here' }), internalId)
            }
          } as MenuListItemProps,
          canDeleteTask &&
            ({
              label: t('actions.delete'),
              a11yTitle: t('actions.delete'),
              icon: 'delete',
              destructive: true,
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                openModal({ id: [id], type: 'tasks-delete' })
              }
            } as MenuListItemProps)
        ].filter(Boolean) as MenuListItemProps[]

        return menuItems
      }
  )
}

const useBuildTaskDependencyMenuItems = () => {
  const toggleTaskEdit = useToggleRightPanel('task-edit')

  return useRecoilCallback(({ snapshot }) => async ({ task, type }: { task: TaskListTask; type: DependencyType }) => {
    const taskLookup = await snapshot.getPromise(taskListLookupState)
    const taskTypeLookup = await snapshot.getPromise(accountTaskTypeLookup)
    const streamLookup = await snapshot.getPromise(streamsLookupState)

    const dependencyIds = type === 'predecessors' ? task.predecessor_ids : task.successor_ids
    const tasks = dependencyIds.map(id => taskLookup[id])

    const menuItems = tasks
      .sort((a, b) => {
        return a.internal_id - b.internal_id
      })
      .map(task => {
        const { internal_id: internalId, name, id } = task
        const taskType = taskTypeLookup[task.task_type_id]

        const iconProps = {
          color: streamLookup[task.stream_id].color,
          icon: taskTypeIcon(taskType.icon, task.stage),
          inProgress: task.stage === 'in-progress',
          isOpaque: task.stage === 'complete',
          stageIcon: stageIconName({
            completionType: task.completion_type,
            stage: task.stage,
            startFixed: task.start_fixed
          })
        }

        const item = {
          icon: <TaskItemIcon iconSize="xsmall" {...iconProps} />,
          label: `#${internalId} ${name}`,
          onClick: () => toggleTaskEdit({ taskId: id })
        }
        return item
      })
    return menuItems
  })
}

/* -------------------------------------------------------------------------- */
/*                                    Modal                                   */
/* -------------------------------------------------------------------------- */

export const useModalValue = () => useRecoilValue(runbookViewState_INTERNAL).modal
export const useModalValueCallback = () =>
  useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).modal
  })

export const useModalClose = () =>
  useRecoilCallback(
    ({ set }) =>
      () =>
        set(runbookViewState_INTERNAL, previousState =>
          produce(previousState, draft => {
            draft.modal.active = undefined
            draft.modal.history = []
          })
        )
  )
export const useModalOpen = () =>
  useRecoilCallback(
    ({ set }) =>
      (modal: ModalActiveType) =>
        set(runbookViewState_INTERNAL, previousState =>
          produce(previousState, draft => {
            draft.modal.active = modal
            if (modal.type === 'tasks-csv-import') {
              // @ts-ignore
              draft.modal.active.data = { idle: true, success: false }
            }
          })
        )
  )
export const useModalContinue = () =>
  useRecoilCallback(
    ({ set }) =>
      (nextModal: ModalActiveType, previousModal: ModalActiveType & { context?: object }) =>
        set(runbookViewState_INTERNAL, previousState =>
          produce(previousState, draft => {
            draft.modal.active = nextModal
            draft.modal.history.push(previousModal)
          })
        )
  )

export const useModalUpdate = () => {
  return useRecoilCallback(({ set }) => async (data?: object) => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        // @ts-ignore
        draft.modal.active.data = data
      })
    )
  })
}

/* -------------------------------------------------------------------------- */
/*                                  Copy IDs                                  */
/* -------------------------------------------------------------------------- */

export const useCopyIdsAdd = () =>
  useRecoilCallback(({ set }) => (ids: number[]) => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.copyIds = ids
      })
    )
  })

export const useCopyIdsRemove = () =>
  useRecoilCallback(({ set }) => () => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.copyIds = []
      })
    )
  })

/* -------------------------------------------------------------------------- */
/*                            New Comments Count                              */
/* -------------------------------------------------------------------------- */

export const useNewCommentsCountValue = () => {
  const { newCommentsCount } = useRecoilValue(runbookViewState_INTERNAL)
  return newCommentsCount
}

export const useNewCommentsCountReset = () => {
  return useRecoilCallback(({ set }) => async () => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.newCommentsCount = 0
      })
    )
  })
}

/* -------------------------------------------------------------------------- */
/*                                Selected Ids                                */
/* -------------------------------------------------------------------------- */

export const useSelectedIdsValue = () => {
  const { selectedIds } = useRecoilValue(runbookViewState_INTERNAL)
  return selectedIds
}

export const useSelectedIdsValueCallback = () =>
  useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).selectedIds
  })

export const useCopyIdsValue = () => {
  const { copyIds } = useRecoilValue(runbookViewState_INTERNAL)
  return copyIds
}

export const useCopyIdsValueCallback = () =>
  useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).copyIds
  })

export const useSelectedIdAdd = () =>
  useRecoilCallback(({ set }) => (id: number) => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.selectedIds.push(id)
      })
    )
  })

export const useSelectedIdRemove = () =>
  useRecoilCallback(({ set }) => (id: number) => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.selectedIds = draft.selectedIds.filter(draftId => draftId !== id)
      })
    )
  })

export const useSelectedIdBulkRemove = () =>
  useRecoilCallback(({ set }) => (ids: number[]) => {
    set(runbookViewState_INTERNAL, prev =>
      produce(prev, draft => {
        pullAllWith(draft.selectedIds, ids)
      })
    )
  })

export const useSelectedIdsOverwrite = () =>
  useRecoilCallback(({ set }) => (ids: number[]) => {
    set(runbookViewState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.selectedIds = ids
      })
    )
  })

export const useSelectedIdToggle = () => {
  const selectedIdRemove = useSelectedIdRemove()
  const selectedIdAdd = useSelectedIdAdd()
  const selectedIdsOverwrite = useSelectedIdsOverwrite()

  return useRecoilCallback(({ snapshot }) => async (id: number, shiftKey: boolean) => {
    const selectedIds = (await snapshot.getPromise(runbookViewState_INTERNAL)).selectedIds
    if (shiftKey && selectedIds.length === 1 && selectedIds[0] !== id) {
      // If holding shift and clicking a different task to the already selected one, select all tasks between the 2
      const taskIds = await snapshot.getPromise(filteredTaskListIdsState)
      const indexOfSelected = taskIds.indexOf(selectedIds[0])
      const indexOfClicked = taskIds.indexOf(id)
      const startIndex = Math.min(indexOfSelected, indexOfClicked)
      const endIndex = Math.max(indexOfSelected, indexOfClicked)
      const newSelectedIds = taskIds.slice(startIndex, endIndex + 1)
      selectedIdsOverwrite(newSelectedIds)
    } else {
      selectedIds.includes(id) ? selectedIdRemove(id) : selectedIdAdd(id)
    }
  })
}

export const useSelectedIdsSelectAll = () => {
  const selectedIdsOverwrite = useSelectedIdsOverwrite()

  return useRecoilCallback(({ snapshot }) => async () => {
    const taskIds = await snapshot.getPromise(filteredTaskListIdsState)
    selectedIdsOverwrite(taskIds)
  })
}

export const useSelectedIdsToggleAll = () => {
  const selectedIdsRemoveAll = useSelectedIdsRemoveAll()
  const selectedIdsSelectAll = useSelectedIdsSelectAll()

  return useRecoilCallback(({ snapshot }) => async () => {
    const selectedIds = (await snapshot.getPromise(runbookViewState_INTERNAL)).selectedIds
    selectedIds.length ? selectedIdsRemoveAll() : selectedIdsSelectAll()
  })
}

export const useSelectedIdsRemoveAll = () => {
  const selectedIdsOverwrite = useSelectedIdsOverwrite()
  return () => selectedIdsOverwrite([])
}

/* -------------------------------------------------------------------------- */
/*                               Highlight Mode                               */
/* -------------------------------------------------------------------------- */

export const useHighlightMode = () => useRecoilValue(runbookViewState_INTERNAL).highlightMode

export const useHighlightModeCallback = () =>
  useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).highlightMode
  })

export const useSetHighlightMode = () =>
  useRecoilCallback(
    ({ set }) =>
      (highlight: boolean) =>
        set(runbookViewState_INTERNAL, previousState =>
          produce(previousState, draft => {
            draft.highlightMode = highlight
          })
        )
  )

/* -------------------------------------------------------------------------- */
/*                             Integration Request                            */
/* -------------------------------------------------------------------------- */

export const useIntegrationRequestValue = () => {
  const { integrationRequest } = useRecoilValue(runbookViewState_INTERNAL)
  return integrationRequest
}
export const useIntegrationRequestValueCallback = () =>
  useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).integrationRequest
  })

export const useIntegrationRequestAdd = () => {
  const setViewState = useSetRecoilState(runbookViewState_INTERNAL)

  return (request: IntegrationRequest) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        const { taskId, type } = request
        draft.integrationRequest[taskId] = type
      })
    )
}

export const useIntegrationRequestRemove = () => {
  const setViewState = useSetRecoilState(runbookViewState_INTERNAL)

  return (taskId: number) =>
    setViewState(previousState =>
      produce(previousState, draft => {
        delete draft.integrationRequest[taskId]
      })
    )
}

/* -------------------------------------------------------------------------- */
/*                                 Permissions                                */
/* -------------------------------------------------------------------------- */

export const useEditRunbookPermissions = () => {
  const versionIsEditable = useRecoilValue(isVersionEditable)
  const hasUpdatePermissions = useActiveRunbookCan('update')
  const hasPermittedStreams = !!useRecoilValue(streamsPermittedState).length

  if (!hasUpdatePermissions && !hasPermittedStreams)
    return { can: false, error: !hasUpdatePermissions ? 'NO_PERMISSION' : 'NO_PERMITTED_STREAMS' }
  if (!versionIsEditable) return { can: false, error: 'VERSION_NOT_EDITABLE' }

  return { can: true }
}

export const useEditRunbookPermissionsCallback = () => {
  return useRecoilCallback(({ snapshot }) => async () => {
    const versionIsEditable = await snapshot.getPromise(isVersionEditable)
    const hasUpdatePermissions = await snapshot.getPromise(runbookPermission({ attribute: 'update' }))
    const hasPermittedStreams = !!(await snapshot.getPromise(streamsPermittedState)).length

    if (!hasUpdatePermissions && !hasPermittedStreams)
      return { can: false, error: !hasUpdatePermissions ? 'NO_PERMISSION' : 'NO_PERMITTED_STREAMS' }
    if (!versionIsEditable) return { can: false, error: 'VERSION_NOT_EDITABLE' }

    return { can: true }
  })
}

export const useCanCreateRootTask = () => {
  const newTaskStreamId = useRecoilValue(newTaskStreamState({}))
  const versionIsEditable = useRecoilValue(isVersionEditable)

  if (!versionIsEditable) return { can: false, error: 'VERSION_NOT_EDITABLE' }
  if (!newTaskStreamId) return { can: false, error: 'NO_PERMITTED_STREAMS' }
  return { can: true }
}
export const useCanCreateRootTaskCallback = () => {
  return useRecoilCallback(({ snapshot }) => async () => {
    const newTaskStreamId = !!(await snapshot.getPromise(newTaskStreamState({})))
    const versionIsEditable = await await snapshot.getPromise(isVersionEditable)

    if (!versionIsEditable) return { can: false, error: 'VERSION_NOT_EDITABLE' }
    if (!newTaskStreamId) return { can: false, error: 'NO_PERMITTED_STREAMS' }
    return { can: true }
  })
}

export const useCanInitiateBulkEditActions = () => {
  const selectedIds = useSelectedIdsValue()
  const editRunbookPermissions = useEditRunbookPermissions()

  if (!editRunbookPermissions.can) return editRunbookPermissions
  if (selectedIds.length === 0) return { can: false, error: 'NO_SELECTED_IDS' }
  return { can: true }
}

export const useCanInitiateBulkProgressionActions = () => {
  const selectedIds = useSelectedIdsValue()
  const hasUpdatePermissions = useActiveRunbookCan('update')
  const stage = useRecoilValue(runbookVersionStageState)

  if (!hasUpdatePermissions) return { can: false, error: 'NO_PERMISSION' }
  if (stage !== 'active') return { can: false, error: 'VERSION_ACTIVE' }
  if (selectedIds.length === 0) return { can: false, error: 'NO_SELECTED_IDS' }
  return { can: true }
}

export const useCanInitiateBulkEditActionsCallback = () => {
  const selectedIdsCallback = useSelectedIdsValueCallback()
  const getEditRunbookPermissions = useEditRunbookPermissionsCallback()

  return useRecoilCallback(() => async () => {
    const editRunbookPermissions = await getEditRunbookPermissions()

    if (!editRunbookPermissions.can) return editRunbookPermissions

    const selectedIds = await selectedIdsCallback()
    if (selectedIds.length === 0) return { can: false, error: 'NO_SELECTED_IDS' }
    return { can: true }
  })
}

export const useCanInitiateBulkProgressionActionsCallback = () => {
  const selectedIdsCallback = useSelectedIdsValueCallback()

  return useRecoilCallback(({ snapshot }) => async () => {
    const selectedIds = await selectedIdsCallback()

    const hasUpdatePermissions = await snapshot.getPromise(runbookPermission({ attribute: 'update' }))
    const stage = await snapshot.getPromise(runbookVersionStageState)

    if (!hasUpdatePermissions) return { can: false, error: 'NO_PERMISSION' }
    if (stage !== 'active') return { can: false, error: 'VERSION_ACTIVE' }
    if (selectedIds.length === 0) return { can: false, error: 'NO_SELECTED_IDS' }
    return { can: true }
  })
}

export const useCanShowPirLink = () => {
  const { stage } = useGetRunbookVersion()
  const pirDashboard = useGetDashboardBy({ key: 'pir' })
  const run = useGetRun()
  const canUpdate = useActiveRunbookCan('update')
  const pirFilter = useGetSavedPirFilter()

  if (!canUpdate && pirFilter) return { can: true }

  if (!canUpdate) return { can: false, error: 'NO_PERMISSION' }
  if (stage !== 'complete') return { can: false, error: 'STAGE_NOT_CORRECT' }
  if (!pirDashboard) return { can: false, error: 'NO_DASHBOARD' }
  if (!run?.run_type || run.run_type !== 'live') return { can: false, error: 'RUN_TYPE_NOT_CORRECT' }
  return { can: true }
}

export const useCanShowPirLinkCallback = () => {
  const getRunbookVersion = useGetRunbookVersionCallback()
  const getDashboardBy = useGetDashboardByCallback()
  const getRun = useGetRunCallback()
  const getPirFilter = useGetSavedPirFilterCallback()

  return useRecoilCallback(({ snapshot }) => async () => {
    const canUpdate = await snapshot.getPromise(runbookPermission({ attribute: 'update' }))
    const { stage } = await getRunbookVersion()
    const pirDashboard = await getDashboardBy({ key: 'pir' })
    const run = await getRun()
    const pirFilter = await getPirFilter()

    if (!canUpdate && pirFilter) return { can: true }

    if (!canUpdate) return { can: false, error: 'NO_PERMISSION' }
    if (stage !== 'complete') return { can: false, error: 'STAGE_NOT_CORRECT' }
    if (!pirDashboard) return { can: false, error: 'NO_DASHBOARD' }
    if (!run?.run_type || run.run_type !== 'live') return { can: false, error: 'RUN_TYPE_NOT_CORRECT' }
    return { can: true }
  })
}

/* -------------------------------------------------------------------------- */
/*                                 Task Create                                */
/* -------------------------------------------------------------------------- */

export const useTaskCreateValue = () => {
  return useRecoilValue(runbookViewState_INTERNAL).taskCreate
}

export const useTaskCreateValueCallback = () =>
  useRecoilCallback(({ snapshot }) => async () => {
    return (await snapshot.getPromise(runbookViewState_INTERNAL)).taskCreate
  })

export const useTaskCreateToggleForm = () =>
  useRecoilCallback(({ set }) => async ({ predecessor }) => {
    set(runbookViewState_INTERNAL, prev =>
      produce(prev, draft => {
        if (draft.taskCreate.predecessor === predecessor) {
          draft.taskCreate.predecessor = undefined
          draft.taskCreate.name = undefined
        } else if (predecessor === 0) {
          draft.taskCreate.predecessor = predecessor
          draft.taskCreate.name = undefined
        } else {
          draft.taskCreate.predecessor = predecessor
        }
      })
    )
  })

export const useTaskCreateOpenForm = () => {
  const setViewState = useSetRecoilState(runbookViewState_INTERNAL)

  const setFromPredecessor = ({ predecessor }: { predecessor?: number }) =>
    setViewState(prev =>
      produce(prev, draft => {
        draft.taskCreate.predecessor = predecessor
        draft.taskCreate.name = undefined
      })
    )

  return setFromPredecessor
}

export const useTaskCreateCloseForm = () => {
  const setViewState = useSetRecoilState(runbookViewState_INTERNAL)

  const onClose = () =>
    setViewState(prev =>
      produce(prev, draft => {
        draft.taskCreate.predecessor = undefined
        draft.taskCreate.name = undefined
      })
    )

  return onClose
}

export const useTaskCreateSetName = () => {
  const setViewState = useSetRecoilState(runbookViewState_INTERNAL)

  const onName = (name: string | undefined) =>
    setViewState(prev =>
      produce(prev, draft => {
        draft.taskCreate.name = name
      })
    )

  return onName
}
